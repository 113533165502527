import { Modal } from 'react-bootstrap';
import { HashPDFModalProps, HashPDFModalSteps } from './types';

import { Invoice } from 'components/ExportPDFModal/types';
import { StatusHash } from 'Functions/invoices/types';
import { verifyHash } from 'Functions/invoices/verifyHash';
import { useMemo, useState } from 'react';
import { renderToast } from 'utils/basic/renderToast';
import { Form } from './components/Form';
import { Loader } from './components/Loader';
import { Result } from './components/Result';
import styles from './styles.module.scss';

export const HashPDFModal = ({ isOpen, handleClose }: HashPDFModalProps) => {
    const [selectedStep, setSelectedStep] = useState<HashPDFModalSteps>('form');

    const [invoice, setInvoice] = useState<Invoice | null>(null);

    const handleValidate = async (hash: string, invoice: string) => {
        const validations = [hash, invoice].reduce((acc, curr) => acc && !!curr, true);

        if (!validations) return;

        setSelectedStep('loading');

        try {
            const response = await verifyHash({ hash, numeroNF: invoice });

            if (response.status === StatusHash.ERROR) {
                return;
            }

            setInvoice(response.data);
        } catch {
        } finally {
            setSelectedStep('result');
        }
    };

    const steps: Record<HashPDFModalSteps, JSX.Element> = useMemo(
        () => ({
            form: <Form handleValidate={handleValidate} handleClose={() => handleClose()} />,
            loading: <Loader />,
            result: (
                <Result
                    invoice={invoice}
                    handleBack={() => {
                        setInvoice(null);
                        setSelectedStep('form');
                    }}
                />
            ),
        }),
        [invoice]
    );

    return (
        <Modal
            contentClassName={styles.modalContent}
            dialogClassName={styles.modalDialog}
            centered
            show={isOpen}
        >
            <Modal.Body className={styles.modal}>{steps[selectedStep]}</Modal.Body>
        </Modal>
    );
};
