import Button from 'components/Button';
import React from 'react';

import { Icon } from 'utils/basic/renderToast';
import theme from 'utils/global/theme/theme';
import { PDFViewer } from '../PDFViewer';
import styles from './styles.module.scss';
import { ResultProps } from './types';

export const Result = ({ invoice, handleBack }: ResultProps) => {
    return (
        <div style={{ minWidth: 960 }}>
            <h3 className={styles.title}>Autenticação PDF do Canhoto</h3>
            {invoice && (
                <div
                    style={{
                        display: 'flex',
                        alignItems: 'center',
                        padding: '8px 16px',
                        background: theme.colors.green_1,
                        gap: 16,
                        borderRadius: 8,
                        color: '#fff',
                        fontFamily: 'Medium',
                        marginBottom: 12,
                    }}
                >
                    <Icon type="success" />
                    Código HASH validado
                </div>
            )}
            {invoice ? (
                <PDFViewer invoice={invoice} />
            ) : (
                <div
                    style={{
                        display: 'flex',
                        alignItems: 'center',
                        padding: '8px 16px',
                        background: theme.colors.red_1,
                        gap: 16,
                        borderRadius: 8,
                        marginBottom: 32,
                        color: '#fff',
                        fontFamily: 'Medium',
                    }}
                >
                    <Icon type="error" />
                    Desculpe, não foi possível localizar a HASH. Tente digitar outro número.
                </div>
            )}

            <Button onClick={handleBack} customClassName={styles.backButton}>
                Voltar
            </Button>
        </div>
    );
};
