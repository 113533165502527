import api from 'Services/api';
import { VerifyHashParams, VerifyHashResponse } from './types';

export const verifyHash = async ({
    hash,
    numeroNF,
}: VerifyHashParams): Promise<VerifyHashResponse> => {
    try {
        const { data: response } = await api.get(
            `/motorista/consultar-canhoto?numeroNF=${numeroNF}&hash=${hash}`
        );
        return {
            ...response,
            data: {
                ...response.data,
                imagemS3: response.data?.assinatura.base64,
                hashAuthenticity: response.data?.aqAssin,
                hashSign: response.data?.imAssin,
            },
        };
    } catch (error) {
        throw error;
    }
};
