import styled from 'styled-components';

export const Container = styled.div`
    width: 100%;
    margin-bottom: 16px;
    padding: 0 32px;
`;

export const Page = styled.div`
    background: '#fff';
    color: 'black';
    border: 1px solid transparent;
    border-bottom-color: #c4c4c4;
`;

export const ContentHolder = styled.section`
    display: flex;
    justify-content: space-between;
`;

export const Section = styled.div`
    display: flex;
    flex-direction: column;
    flex: 1;
    align-items: flex-start;
`;

export const SectionHeader = styled.div`
    flex-direction: column;
    flex: 1;
    justify-content: center;
    align-items: flex-start;
`;

export const TitleHolder = styled.div`
    margin: 0 auto;
    margin-bottom: 12px;
`;

export const Title = styled.h2`
    font-size: 20px;
    font-family: 'Bold Titulos';
    font-weight: bold;
`;

export const Text = styled.p`
    font-size: 12px;
    font-family: 'Medium';
    line-height: 18px;
    margin-bottom: 8px;
`;

export const MultipleText = styled.div`
    display: flex;
    flex-direction: row;
    gap: 12px;
`;

export const ImageHolder = styled.div`
    display: flex;
    flex-direction: 'column';

    justify-content: 'center';
    align-items: 'flex-start';
    margin-bottom: 16px;
`;

export const Logo = styled.img`
    width: 187px;
    height: 51px;
`;

export const SignatureImage = styled.img`
    width: 300px;
    height: 150px;
`;

export const Row = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin: 14px;
    padding: 14px;
`;
export const LegendContainer = styled(Row)`
    margin-top: 22px;
    align-items: center;
    justify-content: center;
    margin-bottom: 0;
    padding-bottom: 4px;
`;
