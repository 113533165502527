import LogoIPP from '../../../../assets/logo-ipiranga-azul.png';
import {
    Container,
    ContentHolder,
    ImageHolder,
    LegendContainer,
    Logo,
    MultipleText,
    Page,
    Row,
    Section,
    SignatureImage,
    Text,
    Title,
    TitleHolder,
} from './styles';
import { PDFViewerProps } from './types';

export const PDFViewer = ({ invoice }: PDFViewerProps) => {
    const formatCPF = (cpf: string) => {
        cpf = cpf.padStart(11, '0');
        return (
            cpf.substring(0, 3) +
            '.' +
            cpf.substring(3, 6) +
            '.' +
            cpf.substring(6, 9) +
            '-' +
            cpf.substring(9)
        );
    };

    const formatCNPJ = (cnpj: string) => {
        cnpj = cnpj.padStart(14, '0');
        return (
            cnpj.substring(0, 2) +
            '.' +
            cnpj.substring(2, 5) +
            '.' +
            cnpj.substring(5, 8) +
            '/' +
            cnpj.substring(8, 12) +
            '-' +
            cnpj.substring(12)
        );
    };

    const formatDate = (date: string) => {
        return date.substring(8, 10) + '/' + date.substring(5, 7) + '/' + date.substring(0, 4);
    };

    const getCNPJOFNFE = (chaveNFe: string) => {
        return formatCNPJ(chaveNFe.substring(6, 20));
    };

    return (
        <Container>
            <Page>
                <ContentHolder>
                    <div>
                        <ImageHolder>
                            <Logo src={LogoIPP} />
                        </ImageHolder>
                        <Section
                            style={{
                                marginLeft: 28,
                            }}
                        >
                            <Text>Emitente: {invoice.emitente.nome}</Text>
                            <Text>CNPJ Emitente: {getCNPJOFNFE(invoice.chaveNFe)}</Text>
                            <Text>Destinatário: {invoice.cliente.nome}</Text>
                            <Text>CNPJ Destinatário: {formatCNPJ(invoice.cliente.cnpj)}</Text>
                            <Text>Endereço do Destinatário: {invoice.cliente.endereco}</Text>
                            <Text>Pedido: {invoice.numeroPedido}</Text>
                            <MultipleText>
                                <Text>NFE: {invoice.numeroNF}</Text>
                                <Text>Série: {invoice?.chaveNFe.substring(22, 25)}</Text>
                            </MultipleText>
                            <Text>
                                CPF do(a) Motorista:{' '}
                                {invoice?.motorista?.cpf
                                    ? formatCPF(invoice?.motorista?.cpf)
                                    : 'Sem registro'}
                            </Text>
                            <Text>
                                Nome do(a) Motorista:{' '}
                                {invoice?.motorista?.nome
                                    ? invoice?.motorista?.nome
                                    : 'Sem registro'}
                            </Text>
                            <Text>
                                Data de Entrega:{' '}
                                {invoice.dataAssinatura && invoice.dataAssinatura !== 'null'
                                    ? invoice.dataAssinatura
                                    : 'Sem registro'}
                            </Text>
                            <Text>
                                Data de Emissão do Canhoto: {formatDate(invoice.dataEmissaoNFe)}
                            </Text>
                            <Text>
                                Geolocalização:{' '}
                                {invoice.geolocalizacao
                                    ? `${invoice.geolocalizacao?.latitude}, ${invoice.geolocalizacao?.longitude}`
                                    : 'Sem registro'}
                            </Text>
                            <Text>
                                Código de Autenticidade da Assinatura: <br />
                                {invoice.hashSign || 'Sem registro'}
                            </Text>
                            <Text>
                                Código de Autenticidade do Canhoto: <br />
                                {invoice.hashAuthenticity || 'Sem registro'}
                            </Text>
                        </Section>
                    </div>

                    <Row style={{ gap: 6 }}>
                        <Section
                            style={{
                                justifyContent: 'flex-start',
                                alignItems: 'flex-start',
                                marginBottom: 0,
                            }}
                        >
                            <TitleHolder>
                                <Title>COMPROVANTE DE RECEBIMENTO</Title>
                            </TitleHolder>

                            <div style={{ justifyContent: 'flex-start', marginLeft: 22 }}>
                                <Text>
                                    CPF do(a) Recebedor(a):{' '}
                                    {invoice?.recebedor
                                        ? formatCPF(invoice?.recebedor?.cpf)
                                        : 'Sem registro'}
                                </Text>
                                <Text>
                                    Nome do Recebedor(a):{' '}
                                    {invoice?.recebedor?.nome || 'Sem registro'}
                                </Text>
                                <Text>
                                    Responsável pelo recebimento:{' '}
                                    {invoice?.recebedor?.funcao || 'Sem registro'}
                                </Text>
                                <SignatureImage
                                    src={'data:image/png;base64,' + invoice?.imagemS3}
                                />
                            </div>
                        </Section>
                    </Row>
                </ContentHolder>

                <LegendContainer>
                    <Text>Disponível para consulta em casos de auditoria e ações judiciais.</Text>
                </LegendContainer>
            </Page>
        </Container>
    );
};
