import { enqueueSnackbar } from 'notistack';
import styles from './styles.module.scss';

export const Icon = ({ type }: { type: 'default' | 'error' | 'success' | 'warning' | 'info' }) => {
    switch (type) {
        case 'success':
            return (
                <svg
                    className={styles.button}
                    width="33"
                    height="37"
                    viewBox="0 0 33 37"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <g clip-path="url(#clip0_1438_2163)">
                        <path
                            d="M6.875 18.5L13.75 26L27.5 11"
                            stroke="white"
                            stroke-width="1.5"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                        />
                    </g>
                    <defs>
                        <clipPath id="clip0_1438_2163">
                            <rect
                                width="33"
                                height="36"
                                fill="white"
                                transform="translate(0 0.5)"
                            />
                        </clipPath>
                    </defs>
                </svg>
            );
        case 'error':
            return (
                <svg
                    className={styles.button}
                    width="33"
                    height="37"
                    viewBox="0 0 33 37"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <g clip-path="url(#clip0_1438_2079)">
                        <path
                            d="M24.75 9.5L8.25 27.5"
                            stroke="white"
                            stroke-width="1.5"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                        />
                        <path
                            d="M8.25 9.5L24.75 27.5"
                            stroke="white"
                            stroke-width="1.5"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                        />
                    </g>
                    <defs>
                        <clipPath id="clip0_1438_2079">
                            <rect
                                width="33"
                                height="36"
                                fill="white"
                                transform="translate(0 0.5)"
                            />
                        </clipPath>
                    </defs>
                </svg>
            );
        default:
            return <></>;
    }
};

export const renderToast = (
    text: string,
    config: {
        type: 'default' | 'error' | 'success' | 'warning' | 'info';
        duration?: number;
    }
) => {
    enqueueSnackbar(
        <div className={styles.row}>
            <Icon type={config.type} />
            {text}
        </div>,
        {
            variant: config.type,
            autoHideDuration: config.duration || 3000,
        }
    );
};
