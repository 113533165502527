import Button from '../../components/Button';
import styles from './styles.module.scss';

interface SaveCancelButtonsProps {
    areaClassName?: string;
    cancelFunc: () => void;
    saveFunc: () => void;
    saveButton?: string;
    cancelButton?: string;
    saveButtonAlternativeText?: string;
    cancelButtonAlternativeText?: string;
    loading?: boolean;
    isDisabled?: boolean;
}

const SaveCancelButtons = ({
    areaClassName = '',
    cancelFunc,
    saveFunc,
    saveButton = '',
    saveButtonAlternativeText = '',
    cancelButton,
    cancelButtonAlternativeText = '',
    loading,
    isDisabled,
}: SaveCancelButtonsProps) => {
    return (
        <div className={`${styles.actionsButtons} ${areaClassName}`}>
            <Button
                classColorButton={styles.cancelText}
                customClassName={`${styles.cancelButton} ${cancelButton}`}
                onClick={cancelFunc}
                disabled={loading}
            >
                <span>{cancelButtonAlternativeText || 'Cancelar'}</span>
            </Button>
            <Button
                customClassName={`${styles.saveButton} ${saveButton} ${
                    isDisabled ? styles.buttonDisabled : ''
                }`}
                classColorButton={styles.saveText}
                onClick={saveFunc}
                isLoading={loading}
                disabled={loading || isDisabled}
            >
                <span>{saveButtonAlternativeText || 'Salvar'}</span>
            </Button>
        </div>
    );
};

export default SaveCancelButtons;
