import React from 'react';
import styles from './styles.module.scss';

export const Loader = () => {
    return (
        <div
            style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                gap: 80,
                margin: 120,
            }}
        >
            <div className={styles.spinner}></div>
            <span style={{ fontWeight: 'bold', fontSize: 24, whiteSpace: 'nowrap' }}>
                Aguarde um momento estamos consultando
            </span>
        </div>
    );
};
