import { Document, Image, Page, StyleSheet, Text, View } from '@react-pdf/renderer';
import { InvoicesBack } from 'components/ExportPDFModal/types';
import LogoIPP from '../../assets/logo-ipiranga-azul.png';

const styles = StyleSheet.create({
    page: {
        backgroundColor: '#fff',
        color: 'black',
    },
    sectionHeader: {
        flexDirection: 'column',
        flex: 1,
        justifyContent: 'center',
        alignItems: 'flex-start',
    },
    section: {
        flexDirection: 'column',
        flex: 1,
        alignItems: 'flex-start',
    },
    viewer: {
        width: '100%',
        height: '40rem',
    },
    text: {
        fontSize: '12px',
        lineHeight: 1.5,
    },
    title: {
        fontSize: '18px',
        fontWeight: 'bold',
    },
    row: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        margin: 10,
        padding: 10,
    },
    rowAlignCenter: {
        alignItems: 'center',
    },
    rowCenter: {
        justifyContent: 'center',
        textAlign: 'center',
    },
    image: {
        width: 187,
        height: 51,
    },
    imageSignature: {
        width: 300,
        height: 150,
    },
});

const formatCPF = (cpf: string) => {
    cpf = cpf.padStart(11, '0');
    return (
        cpf.substring(0, 3) +
        '.' +
        cpf.substring(3, 6) +
        '.' +
        cpf.substring(6, 9) +
        '-' +
        cpf.substring(9)
    );
};

const formatCNPJ = (cnpj: string) => {
    cnpj = cnpj.padStart(14, '0');
    return (
        cnpj.substring(0, 2) +
        '.' +
        cnpj.substring(2, 5) +
        '.' +
        cnpj.substring(5, 8) +
        '/' +
        cnpj.substring(8, 12) +
        '-' +
        cnpj.substring(12)
    );
};

const formatDate = (date: string) => {
    return date.substring(8, 10) + '/' + date.substring(5, 7) + '/' + date.substring(0, 4);
};

const getCNPJOFNFE = (chaveNFe: string) => {
    return formatCNPJ(chaveNFe.substring(6, 20));
};

function InvoicePDFModal({ notas }: InvoicesBack) {
    return (
        <Document
            title="Comprovante de Recebimento"
            author="Ipiranga Produtos de Petróleo SA"
            subject={`Código de Autenticidade do Canhoto: ${
                notas[0]?.hashAuthenticity ? notas[0]?.hashAuthenticity : 'Sem Registro'
            }`}
            producer="Ipiranga Produtos de Petróleo SA"
            creator="Ipiranga Produtos de Petróleo SA"
            key={`Comprovante de Recebimento - ${notas[0]?.numeroNF}`}
            language="pt-BR"
        >
            {notas.map((invoice) => {
                return (
                    <Page break orientation="landscape" size="A4" style={styles.page}>
                        <View
                            style={{
                                ...styles.row,
                                ...styles.rowAlignCenter,
                                padding: '0 10',
                                alignItems: 'flex-end',
                            }}
                        >
                            <View style={styles.sectionHeader}>
                                <Image style={styles.image} src={LogoIPP} />
                            </View>
                            <View style={{ ...styles.sectionHeader, paddingBottom: 6 }}>
                                <Text style={styles.title}>COMPROVANTE DE RECEBIMENTO</Text>
                            </View>
                        </View>
                        <View style={{ ...styles.row, gap: 6 }}>
                            <View
                                style={{
                                    ...styles.section,
                                    justifyContent: 'flex-start',
                                }}
                            >
                                <Text style={styles.text}>Emitente: {invoice.emitente.nome}</Text>
                                <Text style={styles.text}>
                                    CNPJ Emitente: {getCNPJOFNFE(invoice.chaveNFe)}
                                </Text>
                                <Text style={styles.text}>
                                    Destinatário: {invoice.cliente.nome}
                                </Text>
                                <Text style={styles.text}>
                                    CNPJ Destinatário: {formatCNPJ(invoice.cliente.cnpj)}
                                </Text>
                                <Text style={styles.text}>
                                    Endereço do Destinatário: {invoice.cliente.endereco}
                                </Text>
                                <Text style={styles.text}>Pedido: {invoice.numeroPedido}</Text>
                                <View
                                    style={{ display: 'flex', flexDirection: 'row', gap: '12px' }}
                                >
                                    <Text style={styles.text}>NFE: {invoice.numeroNF}</Text>
                                    <Text style={styles.text}>
                                        Série: {invoice?.chaveNFe.substring(22, 25)}
                                    </Text>
                                </View>
                                <Text style={styles.text}>
                                    CPF do Motorista:{' '}
                                    {invoice?.motorista?.cpf
                                        ? formatCPF(invoice?.motorista?.cpf)
                                        : 'Sem registro'}
                                </Text>
                                <Text style={styles.text}>
                                    Nome do Motorista:{' '}
                                    {invoice?.motorista?.nome
                                        ? invoice?.motorista?.nome
                                        : 'Sem registro'}
                                </Text>
                                <Text style={styles.text}>
                                    Data de Entrega:{' '}
                                    {invoice.dataAssinatura && invoice.dataAssinatura !== 'null'
                                        ? invoice.dataAssinatura
                                        : 'Sem registro'}
                                </Text>
                                <Text style={styles.text}>
                                    Data de Emissão do Canhoto: {formatDate(invoice.dataEmissaoNFe)}
                                </Text>
                                <Text style={styles.text}>
                                    Geolocalização:{' '}
                                    {invoice.geolocalizacao
                                        ? `${invoice.geolocalizacao?.latitude}, ${invoice.geolocalizacao?.longitude}`
                                        : 'Sem registro'}
                                </Text>
                                <Text style={[styles.text, { width: '150%' }]}>
                                    Código de Autenticidade da Assinatura:{' '}
                                    {invoice.hashSign || 'Sem registro'}
                                </Text>
                                <Text style={{ ...styles.text, width: '150%' }}>
                                    Código de Autenticidade do Canhoto:{' '}
                                    {invoice.hashAuthenticity || 'Sem registro'}
                                </Text>
                            </View>
                            <View
                                style={{
                                    ...styles.section,
                                    justifyContent: 'flex-start',
                                    alignItems: 'center',
                                }}
                            >
                                <View style={{ display: 'flex', justifyContent: 'flex-start' }}>
                                    <Text style={styles.text}>
                                        CPF do Recebedor:{' '}
                                        {invoice?.recebedor
                                            ? formatCPF(invoice?.recebedor?.cpf)
                                            : 'Sem registro'}
                                    </Text>
                                    <Text style={styles.text}>
                                        Nome do Recebedor:{' '}
                                        {invoice?.recebedor?.nome || 'Sem registro'}
                                    </Text>
                                    <Text style={styles.text}>
                                        Responsável pelo recebimento:{' '}
                                        {invoice?.recebedor?.funcao || 'Sem registro'}
                                    </Text>
                                    <Image
                                        style={styles.imageSignature}
                                        src={'data:image/png;base64,' + invoice?.imagemS3}
                                    />
                                </View>
                            </View>
                        </View>
                        <View style={{ ...styles.row, ...styles.rowCenter }}>
                            <Text style={styles.text}>
                                Disponível para consulta em casos de auditoria e ações judiciais.
                            </Text>
                        </View>
                    </Page>
                );
            })}
        </Document>
    );
}

export default InvoicePDFModal;
