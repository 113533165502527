import React from 'react';
import InputFloatingLabel from 'components/InputFloatingLabel';
import SaveCancelButtons from 'components/SaveCancelButtons';
import styles from './styles.module.scss';
import { FormProps } from './types';
import { useState } from 'react';

export const Form = ({ handleValidate, handleClose }: FormProps) => {
    const [formData, setFormData] = useState({
        invoice: {
            value: '',
            error: '',
        },
        hash: {
            value: '',
            error: '',
        },
    });
    return (
        <div style={{ maxWidth: 470, minWidth: 470, margin: 48 }}>
            <h3 className={styles.title}>Valide seu Canhoto Digital</h3>
            <form
                onSubmit={(e) => {
                    e.preventDefault();
                    handleValidate(formData.hash.value, formData.invoice.value);
                }}
                className={styles.form}
            >
                <InputFloatingLabel
                    label="Número da nota"
                    helperText=""
                    type="text"
                    id="invoice"
                    value={formData.invoice.value}
                    onChange={(e) => {
                        let value = e.target.value;
                        value = value.replace(/[^0-9]/g, '');
                        setFormData((prev) => ({
                            ...prev,
                            invoice: { value, error: '' },
                        }));
                    }}
                />
                <InputFloatingLabel
                    label="10 últimos dígitos da HASH"
                    helperText=""
                    type="text"
                    id="hash"
                    value={formData.hash.value}
                    onChange={(e) => {
                        if (e.target.value.length > 10) return;
                        setFormData((prev) => ({
                            ...prev,
                            hash: { value: e.target.value, error: '' },
                        }));
                    }}
                />

                <SaveCancelButtons
                    areaClassName={styles.containerButtons}
                    cancelFunc={() => handleClose()}
                    saveFunc={() => handleValidate(formData.hash.value, formData.invoice.value)}
                    cancelButton={styles.cancelButton}
                    cancelButtonAlternativeText="Voltar"
                    saveButtonAlternativeText="Validar"
                    isDisabled={!formData.invoice.value || formData.hash.value.length !== 10}
                />
            </form>{' '}
        </div>
    );
};
